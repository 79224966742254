<template>
  <div class="my-ac__card c-pointer" @click="onClick">
    <div class="my-ac__div">
      <img
        src="@/assets/images/my-achievements/certificate/badge-purple.svg"
        alt="badge-blue"
        width="auto"
        height="auto"
        class="ac-card__badge"
      />

      <h6 class="ac-card__title">
        {{ $t("my_achievements.certified") }}
      </h6>
      <p class="ac-card__description">
        {{ credential.display_name }}
      </p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    credential: Object
  },
  methods: {
    onClick() {
      this.$store.commit("SET_CREDENTIAL", this.credential);
      this.$emit("click");
    }
  }
};
</script>
<style lang="scss" scoped>
.my-ac__card {
  background: $brand-neutral-0;
  border-radius: 4px;
  width: 285px;
  height: 165px;
  padding: 8px 16px 16px 16px;
  margin: 0px 24px 24px 0;
  box-shadow: 0px 1px 3px 1px rgba(33, 33, 33, 0.25);
  &:hover {
    box-shadow: 0px 4px 8px 4px rgba(33, 33, 33, 0.1);
  }
  .my-ac__div {
    @include center;
    height: 100%;
    .ac-card__title {
      @include subtitle-regular;
      margin: 20px 0 8px;
      color: $brand-neutral-900;
    }
    .ac-card__description {
      @include body-medium;
      color: $brand-neutral-900;
      margin-bottom: 0;
      @include line-clamp(2);
    }
  }
}
.share-modal {
  .modal-header {
    border-bottom: 2px solid #e8e8e8;
    .b-icon-size {
      font-size: 25px;
    }
  }
  .sd-main {
    .sd-main__img {
      padding-top: 45%;
      justify-content: center;
    }
    .lxp-badge__main {
      h2 {
        color: #52575c;
      }
      .sd-main__des {
        label,
        p {
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          letter-spacing: 0.2px;
          color: #52575c;
        }
        p {
          font-weight: normal;
        }
      }
      .badge-main__share {
        label {
          width: 100%;
        }
      }
    }
  }
}
</style>
